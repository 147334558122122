const SERVER = "https://economic-api.terra-erp.me/api";

export const Constants = {
    ACTION_FREQUENCY_YEARLY: 4,
    ACTION_FREQUENCY_QUARTERLY: 3,
    ACTION_FREQUENCY_MONTHLY: 2,
    ACTION_FREQUENCY_WEEKLY: 1,
    ACTION_STATUS_IN_PROGRESS: 2,
    ACTION_STATUS_DELAY: 4,
    ACTION_STATUS_PLANNED: 1,
    ACTION_STATUS_COMPLETED: 3,
    SERVER,
    STORAGE: SERVER.substr(0, SERVER.length - 4) + "/storage/",
};

export const DISABLE_FIREBASE = true;
